html,
body,
#root-cw {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
* {
    box-sizing: border-box;
}

#root-cw {
    position: relative;
}
